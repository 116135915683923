<template>
  <div class="bgimage-content">
    <div class="bgm-box">
      <el-form :model="bgimageForm" ref="bgmForm" :rules="bgmRules" label-width="120px">
        <el-form-item label="背景标题" prop="title">
          <el-input v-model="bgimageForm.title" style="width: 300px;" />
        </el-form-item>
        <el-form-item label="背景素材">
          <input type="hidden" name="" v-model="bgimageForm.url">
          <el-upload :action="uploadApi"
                     :headers="headers"
                     :name="filename"
                     :file-list="fileList"
                     :limit="1"
                     :on-success="uploadFile"
                     :before-upload="checkUpload"
                     :on-remove="removeImage"
                     style="width: 500px;"
          >
            <el-button type="primary">上传</el-button>
            <template #tip>
              <div class="el-upload__tip">
                点击图片名称即可移除重新上传
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm(bgmForm)">重置</el-button>
          <el-button type="primary" @click="submitForm(bgmForm)">保存</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {useRoute,useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import {bgimageAdd,bgimageInfo} from "@/utils/apis";
const bgimageForm = reactive({
    title: '',
    url: ''
})
const fileList = ref([])
const id = ref(0)
const route = useRoute()
const router = useRouter()
const uploadApi = ref('background/img')

const filename = ref('background_img')
const uploadFile = (res) => {
  console.log('res',res);
  if (res.code === 200) {
    bgimageForm.url = res.data.url;
  } else {
      fileList.value = [];
      ElMessage.warning(res.msg)
  }
}
const checkUpload = () => {
    if (bgimageForm.url.length) {
        return ElMessage.warning('背景图只能上传一张')
    }

}
const removeImage = () => {
    bgimageForm.url = ''
    fileList.value = []
}
const headers = reactive({
  Authorization: localStorage.getItem('token')
})
const bgmForm = ref()
const bgmRules = reactive({
    title: [
      {required: true, message: '背景标题不能为空', trigger: 'blur'},
      {min: 1, max: 20, message: '背景标题字数在1-20之间', trigger: 'blur'}
    ]
})

const resetForm = (formEle) => {
    fileList.value = [];
    formEle.resetFields()
}
const submitForm = async (formEle) => {
    if (!formEle) return
    await formEle.validate((valid, fields) => {
        if (valid) {
            if (!bgimageForm.url) {
              return ElMessage.warning('请上传背景素材')
            }
            addBgImage(bgimageForm)
        } else {
            console.log('取消');
        }
    })
}

const addBgImage = async (form) => {
  if (id.value) {
    form.background_id = id.value
  }
  try {
    const {data, msg} = await bgimageAdd(form)
    ElMessage({
      type: 'success',
      message: msg,
      duration: 1000,
      onClose: () => {
        router.push({name: 'BGMIndex'})
      }

    })
  } catch (e) {
    if (typeof e === 'string') {
      return ElMessage.warning(e)
    }
    ElMessage.warning(e.message)
  }
}

const bgimageDetail = async (id) => {
  try {
    const {data, msg} = await bgimageInfo(id)
    console.log('data', data);
    Object.assign(bgimageForm, {...data})
    fileList.value = [{name: data.title, url: data.url}];
  }catch (e) {
      ElMessage.warning(e)
  }

}
onMounted(() => {
    if (route.query.id) {
        bgimageDetail(route.query.id)
    }
})

</script>

<style scoped lang="scss">
.bgimage-content {
  &, & * {
    box-sizing: border-box;
  }
  padding: 10px;
  .bgm-box {
      margin-top: 20px;
  }

}

</style>